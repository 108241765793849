import { useState } from 'react';
import { FaBars } from 'react-icons/fa6';
import logo from '../assets/logo.png';

function Navbar() {
  const [showDrop, setShowDrop] = useState(false);

  function handleNav(hash) {
    window.location.hash = `#${hash}`;
    setShowDrop(false);
  }

  return (
    <>
      <nav className="navbar">
        <div className="left">
          <a href="/">
            <img 
              src={logo} 
              alt="web design and development agency collaborating to create a great website for their client" 
              className="logo" 
            />
            <h1>Katz Web Solutions</h1>
          </a>
        </div>
        <div className="right">
          <a href="/#services">Services</a>
          <a href="/#about">About</a>
          <a href="/#contact">Contact</a>
          <button onClick={() => setShowDrop(!showDrop)} className="toggledrop">
            <FaBars className="icon" />
          </button>
        </div>
      </nav>
      <div className={`navdrop ${showDrop && 'navdrop-show'}`}>
        <button onClick={() => handleNav('services')}>Services</button>
        <button onClick={() => handleNav('about')}>About</button>
        <button onClick={() => handleNav('contact')}>Contact</button>
      </div>
    </>
  );
}

export default Navbar;