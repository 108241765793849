import { useContext, createContext, useState } from 'react';

const defaultAlert = {
    on: false,
    type: '',
    message: '',
    timer: null
};

const AlertContext = createContext();

export function useAlertContext() {
    return useContext(AlertContext);
}

function AlertProvider({ children }) {
    const [alert, setAlert] = useState(defaultAlert);

    function closeAlert() {
        clearTimeout(alert.timer);
        setAlert(prevAlert => ({
            ...prevAlert,
            on: false
        }));
    }

    function newAlert(type, message) {
        clearTimeout(alert.timer);
        setAlert({
            on: true,
            type,
            message,
            timer: setTimeout(closeAlert, 2500), 
        });
    }

    return (
        <AlertContext.Provider value={{alert, newAlert, closeAlert}}>
            {children}
        </AlertContext.Provider>
    );
}

export default AlertProvider;