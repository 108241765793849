import AlertProvider from './AlertProvider';

function AppProvider({ children }) {
    return (
        <AlertProvider>
            {children}
        </AlertProvider>
    );
}

export default AppProvider;