import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import lostImg from '../assets/lostimg.png';

function NotFound() {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
 
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCount => {
        if (prevCount === 0) {
          clearInterval(countdownInterval);
          navigate('/');
          return prevCount;
        }
        else {
          return prevCount - 1;
        }
      });
    }, 1000);
  }, [navigate]);

  return (
    <main className="main">
      <section className="section notfound">
        <div className="card">
          <div className="left">
            <h1 className="oops">Oops!</h1>
            <h1 className="pnf">Page Not Found</h1>
            <p>We're sending you home in:</p>
            <div className="countdown">
              <h1 className="count">{countdown}</h1>
              <h1 className="s">s</h1>
            </div>
          </div>
          <div className="right">
            <img 
              src={lostImg}
              alt="Error 404 not found lost astronaut" 
              className="img"
            />
          </div>
        </div>
      </section>
    </main>
  );
}

export default NotFound;