import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppProvider from './providers/AppProvider';
import Navbar from './components/Navbar';
import Alert from './components/Alert';
import Footer from './components/Footer';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
import './styles.css';

function App() {
  return (
    <AppProvider>
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/termsandconditions" element={<Terms />} /> 
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </AppProvider>
  );
}

export default App;
