// import { useState } from 'react';
// import { useAlertContext } from '../providers/AlertProvider';
import { FaServer, FaSearchengin, FaGlobe, FaTools, FaEye, FaClock, FaCalendarAlt } from 'react-icons/fa';
import { FaChartSimple, FaShieldHalved, FaCode, FaPaintbrush, FaEnvelope, FaPhone } from 'react-icons/fa6';
import img1 from '../assets/img1.jpg';
import img3 from '../assets/img3.jpg';

function Home() {

  // const { newAlert } = useAlertContext();

  // function handleNewAlert() {
  //   // newAlert('fail', 'Please fill out all fields');
  //   newAlert('fail', 'Please fill out all fields');
  // }

  return (
    <main className="main">
      <section className="section hero" id="hero">
        <div className="card">
          <div className="left">
            <div className="motto">
              <h1>Modern Websites</h1>
              <h1>Built To Convert</h1>
            </div>
            <div className="desc">
              <p>We build and manage professional, user-friendly websites for small businesses at affordable costs.</p>
            </div>
            <div className="cta">
              <a href="#contact">Let's Talk</a>
            </div>
          </div>
          <div className="right">
            <img 
              src={img1} 
              alt="web design and development agency small team discussing how they can best meet the needs of their client and complete the project on time" 
              className="img1" 
            />
          </div>
        </div>
      </section>
      <section className="section services" id="services">
        <h1 className="title">Our Services</h1>
        <div className="container">
          <div className="card">
            <FaPaintbrush className="icon" />
            <h1 className="name">Web Design</h1>
            <p>We design your website to maximize conversions, ensuring a seamless experience across all devices.</p>
          </div>
          <div className="card">
            <FaCode className="icon" />
            <h1 className="name">Web Development</h1>
            <p>We develop your functional website using the modern, front-end development framework known as React.</p>
          </div>
          <div className="card">
            <FaGlobe className="icon" />
            <h1 className="name small">Domain Management</h1>
            <p>We handle your domain registration and renewal, as well as DNS and SSL configuration and management.</p>
          </div>
          <div className="card">
            <FaServer className="icon" />
            <h1 className="name">Web Hosting</h1>
            <p>We configure and manage fast, reliable servers to host your website and minimize downtime.</p>
          </div>
          <div className="card">
            <FaSearchengin className="icon" />
            <h1 className="name">SEO</h1>
            <p>We optomize your website to rank higher in search enginges, driving more traffic and potential customers to you.</p>
          </div>
          <div className="card">
            <FaShieldHalved className="icon" />
            <h1 className="name">Cybersecurity</h1>
            <p>We protect your site against potential cyber threats or attacks with robust security measures.</p>
          </div>
          <div className="card">
            <FaChartSimple className="icon" />
            <h1 className="name">Analytics</h1>
            <p>We gain insights into your website's incoming traffic with detailed analytics, helping your make data-driven decisions.</p>
          </div>
          <div className="card">
            <FaEye className="icon" />
            <h1 className="name">Monitoring</h1>
            <p>We continuously monitor your site's uptime and performance so that issues are detected and resolved swiftly.</p>
          </div>
          <div className="card">
            <FaTools className="icon" />
            <h1 className="name">Maintenance</h1>
            <p>We enact regular updates to keep your website running efficiently and up to date with the latest features.</p>
          </div>
        </div>
      </section>
      <section className="section about" id="about">
        <div className="card">
          <div className="left">
            <img src={img3} alt="" />
          </div>
          <article className="right">
            <h1>About Us</h1>
            <p>
              We are a small team of skilled web developers who truly love the art of building and managing websites.
              Our mission is to provide high-quality, responsive, and professional-looking websites to small businesses at exceedingly low costs.
              We care about small businesses and want every company, regardless of size and budget, to have the opportunity to have their own website.
            </p>
          </article>
        </div>
      </section>
      <section className="section contact" id="contact">
        <div className="container">
          <div className="card left">
            <div>
              <h1 className="title">Contact Us</h1>
              <p className="text">
                Reach out to us and we'll get back to you shortly with the next steps!
              </p>
            </div>
            {/* <button onClick={() => handleNewAlert()}>alert</button> */}
            <div>
              <a href="#asdf" className="email">
                <FaEnvelope className="icon" />
                <p>team@katzwebsolutions.com</p>
              </a>
              <a href="#asdf" className="phone">
                <FaPhone className="icon" />
                <p>+1 (203) 917-5930</p>
              </a>
              <div className="availability">
                <div className="days">
                  <FaCalendarAlt className="icon" />
                  <p>Mon - Fri</p>
                </div>
                <div className="hours">
                  <FaClock className="icon" />
                  <p>8am - 5:30pm</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card right">
            <div className="input">
              <p>Your Name</p>
              <input />
            </div>
            <div className="input">
              <p>Company Name</p>
              <input />
            </div>
            <div className="input">
              <p>Email</p>
              <input />
            </div>
            <div className="input">
              <p>Phone Number</p>
              <input />
            </div>
            <button>
              Submit
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
