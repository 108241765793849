import { useAlertContext } from '../providers/AlertProvider';
import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';

function Alert() {
  const { alert, closeAlert } = useAlertContext();

  function handleCloseAlert() {
    closeAlert();
  }

  return (
    <div className={`alert ${alert.on && 'alert-on'}`}>
      <div className="left">
        {alert.type === 'success' ?
          <FaCheckCircle className="icon icon-success" />
        :
          <FaExclamationCircle className="icon icon-fail" />
        }
        <p>{alert.message}</p>
      </div>
      <div className="right">
        <button className="close" onClick={handleCloseAlert}>
            <FaXmark className="icon" />
        </button>
      </div>
    </div>
  );
}

export default Alert;