const year = new Date().getFullYear();

function Footer() {
    return (
        <footer className="footer">
            <div className="legal">
                <a href="/privacypolicy">Privacy Policy</a>
                <p>|</p>
                <a href="/termsandconditions">Terms & Conditions</a>
            </div>
            <p>&copy; {year} Katz Web Solutions LLC. All Rights Reserved.</p>
        </footer>
    );
}

export default Footer;